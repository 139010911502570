import { type UserAuthenticatedResponse } from '../../services/api-service';
import { type LoginType } from '../../utils/redirect-to';

export const EMAIL_PATTERN = /^[A-Z0-9'._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

export type SetLoginType = (loginType: LoginType | null) => void;
export type LoginSuccessCallback = (resp: UserAuthenticatedResponse) => void;

export interface GoogleLocationState {
  redirectTo: Nullable<string>;
}
